/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Nunito", sans-serif;
    background-color: #f5f7f8;
}

.add-button:active {
    background-color: #8ab8ae !important;
    transform: scale(0.95);
}

.button:active {
    background-color: #8ab8ae !important;
    transform: scale(0.95);
}

.search-button:active {
    background-color: #8ab8ae !important;
    transform: scale(0.95);
}
